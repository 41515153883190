import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import gsap from "gsap";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import Layout from "../../components/layout";

const AboutUsPageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "What-We-Do-Image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "About Us Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "About Us",
				item: {
					url: `${siteUrl}/dubai/about-us`,
					id: `${siteUrl}/dubai/about-us`,
				},
			},
		],
	};
	const enterAnimation = (text) => {
		gsap.to(
			text,

			{ color: "#FFCA2C", duration: 0.5 }
		);
	};
	const exitAnimation = (text) => {
		gsap.to(
			text,

			{ color: "#F5F5F5", duration: 0.5 }
		);
	};
	const enterAnimationBlue = (text) => {
		gsap.to(
			text,

			{ color: "#40B4E5", duration: 0.5 }
		);
	};
	const exitAnimationBlue = (text) => {
		gsap.to(
			text,

			{ color: "#F5F5F5", duration: 0.5 }
		);
	};
	const enterAnimationDarkBlue = (text) => {
		gsap.to(
			text,

			{ color: "#023A7F", duration: 0.5 }
		);
	};
	const exitAnimationDarkBlue = (text) => {
		gsap.to(
			text,

			{ color: "#F5F5F5", duration: 0.5 }
		);
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/about-us`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/dubai/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`ABOUT \n<span class="text-secondary">US</span>`}
						description="Find out more about the RJM team."
					/>
				</section>
				<section className="bg-light-grey py-5 py-lg-10">
					<Container>
						<Row className="align-items-center justify-content-between ">
							<Col lg={5} className=" ">
								<h2 className=" text-primary display-5 ssp-bold mb-5">
									WHO WE ARE
								</h2>
								<p className="mb-5 fs-4">
									RJM digital offer expert advice and website skills alongside
									high quality customer service, so you get the website your
									business needs
								</p>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1?.altText}
									/>
								</div>
							</Col>
							<Col lg={5}>
								<h3 className="fs-4 mt-5 mt-lg-0">Established</h3>
								<p>
									Formed in 2018, RJM have worked with customers across the
									globe.
								</p>
								<h3 className="mt-5 fs-4">Solution focused</h3>
								<p>
									Whether it's a simple static website or a more complex job
									that needs something custom made, our developers are focused
									on giving our customers what they need. 
								</p>
								<h3 className="mt-5 fs-4">Results driven</h3>
								<p>
									We want to monitor your website results so you know that
									you're getting a good return on your investment. 
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey pb-5 pb-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between ">
							<Col lg={5} className=" ">
								<h2 className=" text-primary display-5 ssp-bold mb-5">
									Our values
								</h2>
								<p className="">
									We pride ourselves on not only having the most up to date
									skills and technology to help your business, but we also
									believe in being transparent about our approach and what we
									can deliver, crafting something that will deliver your
									business needs. 
								</p>
								<p>
									Our team will always work with integrity and ensure that the
									services we provide you are fit for purpose but are also
									results driven, so you can see the impact of the changes we
									deliver.{" "}
								</p>
								<p>
									We want to give you the website, SEO and adverts that will
									build your brand, grow your business and increase your sales.
								</p>
							</Col>
							<Col className="d-none d-lg-block" lg={6}>
								<h2
									onMouseEnter={() => enterAnimationBlue(".grow-hover-1-blue")}
									onMouseLeave={() => exitAnimationBlue(".grow-hover-1-blue")}
									className="display-2 mb-5 grow-hover-1-blue text-center text-xl-start"
								>
									TRUST
								</h2>
								<h2
									onMouseEnter={() => enterAnimation(".grow-hover-2-yellow")}
									onMouseLeave={() => exitAnimation(".grow-hover-2-yellow")}
									className="display-2 mb-5 grow-hover-2-yellow text-center text-xl-end"
								>
									EXPERTISE
								</h2>
								<h2
									onMouseEnter={() =>
										enterAnimationDarkBlue(".grow-hover-3-dark-blue")
									}
									onMouseLeave={() =>
										exitAnimationDarkBlue(".grow-hover-3-dark-blue")
									}
									className="display-2 mb-5 grow-hover-3-dark-blue text-center text-xl-start"
								>
									TRANSPARENT
								</h2>
								<h2
									onMouseEnter={() => enterAnimation(".grow-hover-4-yellow")}
									onMouseLeave={() => exitAnimation(".grow-hover-4-yellow")}
									className="display-2 mb-5 grow-hover-4-yellow text-center text-xl-end"
								>
									INTEGRITY
								</h2>
							</Col>
							<Col className="d-lg-none text-dark-med-grey mt-5" lg={6}>
								<h2 className="display-2 mb-5 text-center">TRUST</h2>
								<h2 className="display-2 mb-5 text-center">EXPERTISE</h2>
								<h2 className="display-2 mb-5 text-center">TRANSPARENT</h2>
								<h2 className="display-2 mb-5 text-center">INTEGRITY</h2>
							</Col>
						</Row>
						<Row>
							<Col className="d-none d-lg-block">
								<h2
									onMouseEnter={() =>
										enterAnimationDarkBlue(".grow-hover-5-dark-blue")
									}
									onMouseLeave={() =>
										exitAnimationDarkBlue(".grow-hover-5-dark-blue")
									}
									className="display-1 mb-6 grow-hover-5-dark-blue text-center"
								>
									RESULT-DRIVEN
								</h2>
							</Col>
							<Col className="d-lg-none">
								<h2 className="display-1 mb-6 text-dark-med-grey text-center">
									RESULT-DRIVEN
								</h2>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section>
					<TeamSection />
				</section> */}
				{/* <Memberships /> */}
				{/* <OSSection
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/> */}
			</Layout>
		</>
	);
};

export default AboutUsPageDubai;
